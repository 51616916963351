<template>
  <div v-if="EditItem" class="reportDialogDetail">
    <el-dialog :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false"
      :destroy-on-close="true" @close="closeDialog" custom-class="cus_dialog" width="800px">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">{{ EditItem.report_name }}</span>
      </div>
      <div>
        <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
          <span></span>
          <span style="font-weight: 400">报告内容</span>
        </div>
        <el-form :model="EditItem" ref="EditItem" label-width="90px" label-position="left">
          <el-row style="padding-top: 10px;">
            <el-col :span="12">
              <el-form-item label="学生姓名">
                <el-input v-model="EditItem.stu_name" readonly style="width:200px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="班级">
                <el-input v-model="EditItem.class_name" readonly style="width:200px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="padding-top: 0px;">
            <el-col :span="12">
              <el-form-item label="提交日期">
                <el-input :value="EditItem.create_date" readonly style="width:200px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否补交">
                <el-input readonly :value="EditItem.is_replenish == 1 ? '是' : '否'" style="width:200px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="padding-top: 0px;" v-for="(item, idx) in EditItem.report_info" :key="idx">
            <el-col :span="22">
              <el-form-item :label="item.name" v-if="item.type == 'textarea'">
                <el-input v-if="isEdit" type="textarea" :autosize="{ minRows: 2, maxRows: 10 }"
                          :value="item.real_values" v-model="item.real_values"></el-input>
                <div v-else class="textarea_indent" v-html="item.real_values_html|| item.real_values||'-'">

                </div>
              </el-form-item>
              <el-form-item :label="item.name"
                v-if="item.type == 'text' || item.type == 'radio'">
                <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 10 }" readonly
                  :value="item.real_values"></el-input>
              </el-form-item>
              <el-form-item :label="item.name" v-if="item.type == 'checkbox'">
                <el-input v-if="item.real_values" type="textarea" :autosize="{ minRows: 2, maxRows: 10 }" readonly
                  :value="item.real_values.join(',')"></el-input>
              </el-form-item>
              <el-form-item :label="item.name" v-if="item.type == 'file'">
                <div style="width:100%">
                  <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
                    v-for="(img, i) in item.real_values" :key="i" v-if="img.type != 'file'">
                    <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                      :preview-src-list="item.real_values.map(e => { return e.fileUrl || e.src })" img="cover"
                      :z-index="3000"></el-image>

                    <div class="imgremove" v-if="isEdit" @click="removeImg(i)">
                      <i class="el-icon-remove"></i>
                    </div>
                  </div>
                  <div style="display:inline-block;vertical-align: top;" v-if="isEdit">
                    <el-upload style="display: inline-block; margin-right: 20px" action :http-request="uploadOss"
                               :on-success="uploadFiles" :show-file-list="false" name="image">
                      <div class="imgaddbtn">＋</div>
                    </el-upload>
                  </div>
                  <div style="line-height: normal;">
                    <!-- <el-divider content-position="left">其他附件</el-divider> -->
                    <p v-for="(file, i) in item.real_values" :key="i" v-if="file.type == 'file'">
                      <i v-if="isEdit" class="el-icon-remove" style="margin-right: 15px;cursor: pointer" @click="removeImg(i)"></i>
                      <el-link type="primary" icon="el-icon-download" :href="file.fileUrl">{{ file.fileName }}</el-link>
                    </p>
                    <div class="" v-if="!item.real_values || item.real_values.length == 0" style="line-height:40px;">-
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="" v-if="EditItem.report_status == 1 || EditItem.report_status == -1 || EditItem.report_status == 2">

            <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
              <span></span>
              <span style="font-weight: 400">审批结果</span>
            </div>
            <el-form-item label="审批结果" style="padding-top:10px;">
              <div>
                <el-tag type="success" v-if="EditItem.report_status == 1" size="small">审批通过</el-tag>
                <el-tag type="danger" v-if="EditItem.report_status == -1" size="small">审批拒绝</el-tag>
                <el-tag type="warning" v-if="EditItem.report_status == 2" size="small">审批撤回</el-tag>
              </div>
            </el-form-item>
            <el-form-item label="评分">
              <div style="display:inline-block">
                <el-rate readonly disabled v-model="EditItem.score" show-text
                  :texts="['差', '中', '中', '良', '优']"></el-rate>
              </div>
            </el-form-item>
            <el-form-item label="评语">
              <div>
                <span style="">{{ EditItem.review_comment || '-' }}</span>
              </div>
            </el-form-item>
            <el-form-item label="撤回原因" v-if="EditItem.report_status == 2">
              <el-col :span="22">
                <div>
                  <span style="">{{ EditItem.revoke_reason || '-' }}</span>
                </div>
              </el-col>
            </el-form-item>
          </div>
        </el-form>

        <el-row v-if="EditItem.report_status == 0 && !isEdit && !isView">
          <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
            <span></span>
            <span style="font-weight: 400">审批</span>
          </div>
          <el-form :model="EditItem" ref="EditItem" label-width="90px" label-position="left">

            <el-form-item label="评分">
              <div class="flexStart">
                <el-rate v-model="score" show-text :texts="['差', '中', '中', '良', '优']"></el-rate>
                <div class="" style="font-size:14px;color:#909399;">（可通过鼠标点击星星进行选择）</div>
              </div>
            </el-form-item>
          </el-form>

          <el-form :model="EditItem" ref="EditItem" label-width="90px" label-position="top">

            <el-form-item label="评语" v-if="needspword != 2" :span="24" class="specialForm">
              <div class="flexStart" slot="label">
                <div class="" style="white-space:nowrap;">评语</div>
                <el-tag size="small" @click="showCommonWords = true"
                  style="cursor: pointer;margin-left:50px;">选择常用语</el-tag>
              </div>
              <div class="flexStart">
                <el-input v-model.trim="spword" type="textarea" :autosize="{ minRows: 2, maxRows: 100 }"
                  placeholder="审核拒绝时请填写拒绝原因" style="margin-top: 5px;"></el-input>
              </div>
            </el-form-item>
          </el-form>
        </el-row>

        <div style="height:50px"></div>
      </div>

      <div slot="footer" v-if="EditItem.report_status == 0 && !isEdit && !isView">
        <el-button size="small" icon="el-icon-close" type="danger" @click="submitForm(-1)">审批拒绝</el-button>
        <el-button size="small" icon="el-icon-check" type="success" @click="submitForm(1)">审批通过</el-button>
      </div>
      <div slot="footer" v-if="EditItem.report_status == 1 && revoke" class="flexStart">
        <div v-if="needspword != 2" class="flex_1">
          <el-input v-model.trim="spword" type="textarea" :autosize="{ minRows: 1, maxRows: 100 }" maxlength="200"
            show-word-limit placeholder="撤回原因，非必填" style="width:100%;"></el-input>
        </div>
        <el-button class="ml10" size="small" icon="el-icon-refresh-left" type="warning"
          @click="submitForm(3)">撤回</el-button>
      </div>

      <div slot="footer" v-if="isEdit">
        <el-button size="small" type="primary" @click="saveForm">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog title="选择常用语" :visible.sync="showCommonWords" :modal-append-to-body="false" :destroy-on-close="true"
      @close="showCommonWords = false" custom-class="cus_dialog" width="800px">

      <el-row class="flexStart" v-if="inputVisible">
        <el-input class="flex_1" type="textarea" autosize v-model="content" ref="saveInput" placeholder="请输入内容">
        </el-input>
        <el-button class="ml10" size="small" icon="el-icon-check" type="primary" @click="saveComment"
          style="text-align: right">提交</el-button>
      </el-row>
      <el-button v-else type="primary" size="small" style="border-style: dashed" @click="showInput">+新增</el-button>

      <el-table v-tableFit :data="commonWordsList" size="" stripe border height="45vh" style="width:100%;margin-top:10px;"
        @cell-click="editContent" :key="randomKey">
        <el-table-column prop="content" label="常用语" width="600px" align="center">
          <template slot-scope="scope">
            <el-input v-if="scope.row[scope.column.property + 'isShow']" type="textarea"
              :autosize="{ minRows: 1, maxRows: 4 }" :ref="scope.column.property" v-model="scope.row.content"
              @blur="alterData(scope.row, scope.column)" style="width:100%"></el-input>
            <span v-else>{{ scope.row.content }}</span>
          </template>

        </el-table-column>

        <el-table-column label="操作" min-width="120" align="center">
          <template slot-scope="scope">
            <el-tag class="pointer" type="primary" size="mini"
              @click="e => chooseCommonWords(e, scope.row.content)">选择</el-tag>
            <el-tag class="pointer ml10" type="danger" size="mini" @click="e => removeComment(scope.row)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>

      <!-- <div style="margin-bottom: 10px;margin-top: 10px;" v-for="(item, index) in commonWordsList" :key="index">
        <el-tag closable style="white-space: inherit;height: inherit" @close="removeComment(item)"
          @click="e => chooseCommonWords(e, item.content)">
          <span style="margin-right: 10px;">{{ item.content }}</span>
          <i class="el-icon-edit" style="cursor: pointer;" @click.stop="changeComment(item)"></i>
        </el-tag>
      </div> -->
      <!--      <el-radio-group v-model="spword">
        <div v-for="(item,index) in commonWordsList">
          <div
            class="flexStart"
            style="border: 1px solid #efefef;padding: 20px;margin-bottom: 10px;"
          >
            <el-radio
              :label="item.content"
              @change="e=>chooseCommonWords(e,item.content)"
              style="white-space: unset!important;"
            >{{ item.content }}</el-radio>
          </div>
        </div>
      </el-radio-group>-->
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["id", "revoke","isEdit","isView"],
  data() {
    return {
      title: "",
      dialogshow: true,
      userinfo: null,
      EditItem: null,
      process_code: "leave_1",
      needChoose: false, //是否需要选择下级
      choosedUser: [], //已选择的用户.
      spword: "",
      score: 5,
      listForm: [],
      listState: [],
      showCommonWords: false,
      commonWordsList: null, //常用语列表
      needspword: 1,//1需要，2，不需要
      content: "",
      inputVisible: false,
      contentId: 0,

      randomKey: Math.random(),
    };
  },
  mounted() {
    this.getTeacheInfo();
    this.getConfig();
    this.getData();
    this.getmyCommentList();
  },
  methods: {
    getTeacheInfo() {
      return new Promise(resolve => {
        this.$http.post("/api/get_teacher_info").then(res => {
          this.userinfo = res.data
          resolve()
        })
      })
    },
    //审批是否需要评语
    getConfig() {
      this.$http.post("/api/sys_sch_config_list", { ktype: "ktype", keyword: 'report_sp_needspword' }).then(res => {
        if (res.data.data && res.data.data[0]) {
          this.needspword = res.data.data[0].kvalue
        }

      })
    },
    getData() {
      this.$http
        .post("/api/report_approval_info_byid", { id: this.id })
        .then(res => {
          res.data.report_info = JSON.parse(res.data.report_info);
          console.log(res.data.report_info);
          this.EditItem = res.data;
        });
    },
    //获取常用语列表
    getmyCommentList() {
      let _this = this;
      _this.$http
        .post("/api/common_content_list", { page: 1, pagesize: 100000 })
        .then(function (res) {
          console.log(686868, res.data.data);
          if (res.data.data && res.data.data.length > 0) {
            _this.commonWordsList = res.data.data;
          }
        });
    },
    chooseCommonWords(e, val) {
      console.log(20000, val);
      this.spword = val
      this.showCommonWords = false;
    },
    closeDialog() {
      this.$$parent(this, "dialogshow", false);
    },
    uploadFiles(e) {
      let ext = e.fileName.substring(e.fileName.lastIndexOf('.')+1).toLowerCase()
      let fileType = "file"
      if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'gif') {
        fileType = "image"
      }
      this.EditItem.report_info.forEach(r => {
        if (r.type == 'file') {
          r.real_values.push({fileName:e.fileName,fileUrl:e.src,type:fileType})
        }
      })
    },

    removeImg(idx) {
      let reports = this.EditItem.report_info
      reports.forEach(r => {
        if (r.type == 'file') {
          r.real_values.splice(idx,1)
        }
      })
      //this.EditItem.fieldname.splice(idx, 1);
    },

    isChooseuser(e) {
      //设置是否需要选人
      this.needChoose = e;
    },
    chooseUser(e) {
      //设置选择的人

      this.choosedUser = e;
    },
    submitForm(st) {
      if (st == 3) {
        this.$confirm("确定撤回?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          if (!this.taped) {
            this.taped = 1;
            setTimeout(() => {
              this.taped = 0;
            }, 3000);
          } else {
            return;
          }
          if (this.spword.length > 200) {
            this.$message.error("撤回原因最多200字")
            return
          }

          this.$http
            .post("/api/report_approval_revoke", {
              id: this.EditItem.id,
              revoke_reason: this.spword
            })
            .then(res => {
              this.$message({
                type: "success",
                message: "撤回成功!"
              });
              this.$$parent(this, "getList");
              this.$$parent(this, "dialogshow", false);
            });
        });
        return
      }
      this.getTeacheInfo().then(rr => {
        console.log(269269269, this.userinfo.sign_img)
        if (!this.userinfo.sign_img || this.userinfo.sign_img == '') {
          this.$alert("请先在小程序设置手写签名！")
          return
        }

        if (st == 1 && this.score <= 0) {
          this.$message.error("请填写评分");
          return;
        }
        if (st == -1) {
          //审批拒绝
          if (!this.spword && this.needspword != 2) {
            this.$message.error("请填写审批拒绝原因");
            return;
          }
        }
        if (!this.spword && this.$store.state.pmid == 214) {
          this.$message.error("请填写评语~");
          return;
        }

        this.$confirm("确定审核?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          if (!this.taped) {
            this.taped = 1;
            setTimeout(() => {
              this.taped = 0;
            }, 3000);
          } else {
            return;
          }

          this.$http
            .post("/api/report_approval_check", {
              id: this.EditItem.id,
              report_status: st,
              review_comment: this.spword,
              score: this.score
            })
            .then(res => {
              this.$message({
                type: "success",
                message: "审核成功!"
              });
              this.$$parent(this, "getList");
              this.$$parent(this, "dialogshow", false);
            });
        });
      })
    },
    editContent(row, column) {
      console.log(row,)
      if (column.label == '常用语') {
        //这里是点击让el-input显示
        row[column.property + 'isShow'] = true
        //这里是编辑完一个存一个，存到liscommonWordsListt1里面，且将重复编辑的去掉（去重）
        // for (let index in this.commonWordsList) {
        //   if (this.commonWordsList[index].id == row.id) {
        //     this.commonWordsList.splice(index, 1)
        //   }
        // }
        // this.commonWordsList.push(row)
        // refreshTable是table数据改动时，刷新table的
        this.refreshTable()
        this.$nextTick(() => {
          this.$refs[column.property] && this.$refs[column.property].focus()
        })
      }
    },
    //表格触发事件
    alterData(row, column) {
      console.log(11111, row, column)
      this.contentId = row.id
      this.content = row.content
      this.saveComment()
      row[column.property + 'isShow'] = false
      //this.refreshTable()//每编辑一个单元格，就会更新一下表格，如果数据量较大的时候，会编辑完一个单元格，表格更新，页面就会返回到顶部，你需要找到刚编辑过的地方，继续编辑，页面体验差，所以我没加
    },
    //更新表格
    refreshTable() {
      this.randomKey = Math.random()
    },

    saveComment() {
      if (!this.content) {
        this.$message.error("内容不能为空");
        return;
      }
      this.$http.post("/api/common_content_edit", {
        id: this.contentId,
        content: this.content
      }).then(res => {
        this.$message({
          type: "success",
          message: "保存成功!"
        });
        this.contentId = 0
        this.content = ""
        this.getmyCommentList()
        this.inputVisible = false
      })
    },
    changeComment(row) {
      this.inputVisible = true
      this.contentId = row.id
      this.content = row.content
      this.$nextTick(() => {
        this.$refs.saveInput.focus()
      })
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveInput.focus()
      })
    },
    removeComment(row) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$http
          .post("/api/common_content_delete", {
            id: row.id,
          })
          .then(res => {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.getmyCommentList()
          });
      });
    },
    saveForm() {
      this.EditItem.report_info = JSON.stringify(this.EditItem.report_info)
      this.$confirm("确定修改吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$http.post('/api/report_approval_edit', this.EditItem).then(res => {
          this.$message.success('修改成功')
          this.$$parent(this, "getList");
          this.$$parent(this, "dialogshow", false);
        })
      })
    },
  }
};
</script>


<style>
.reportDialogDetail .el-form-item {
  margin-bottom: 10px;
}

.reportDialogDetail .specialForm .el-form-item__label {
  padding-bottom: 0px;
}

.reportDialogDetail .textarea_indent {
  min-height: 54px;
  max-height: 300px;
  overflow-y: auto;
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #FFF;
  background-image: none;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
}

.reportDialogDetail .textarea_indent,
.reportDialogDetail.textarea_indent .el-textarea__inner {
  text-indent: 2em;
}

.reportDialogDetail .textarea_indent p {
  margin: 0;
  line-height: unset;
}
</style>

<style scoped>
.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}

.cellGroupBarTitle span:nth-of-type(2) {
  color: #2469f3;
}
</style>
